import { useEffect } from "react";
import "./App.css";
import Routings from "./Components/main/Routes/Routes";

function App() {
  return (
    <div>
      <Routings />
    </div>
  );
}

export default App;
